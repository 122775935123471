import jwt_decoder from 'jwt-decode';

export function isLogin() {
  let enc_token = localStorage.getItem("userToken");
  if(enc_token === null) {
    return false
  }
  let dec_token = jwt_decoder(enc_token);
  let now_time = new Date();
  if(now_time.getTime()/1000 > dec_token["exp"]) {
    localStorage.removeItem("userToken");
    return false
  }
  return true;
}

export function getUserName() {
  let userName = null;
  let enc_token = localStorage.getItem("userToken");
  if(enc_token === null) {
    return userName;
  }
  let dec_token = jwt_decoder(enc_token);
  // console.log(dec_token);
  userName = dec_token['sub'];
  return userName;
}
export function getUserId() {
  let userId = null;
  let enc_token = localStorage.getItem("userToken");
  if(enc_token === null) {
    return userId;
  }
  let dec_token = jwt_decoder(enc_token);
  // console.log(dec_token);
  userId = dec_token['jti'];
  return userId;
}

function getDecToken() {
  let enc_token = localStorage.getItem("userToken");
  if(enc_token == null) {
    return null
  }
  return jwt_decoder(enc_token)
}

export function getUserAvatar() {
  let avatar = null
  let dec_token = getDecToken()
  // console.log(dec_token);
  if(dec_token !== null) {
    avatar = dec_token['avatar']
  }
  // console.log('avatar', avatar)
  return avatar
}
//当访问用户主页时，判断是否是 本人的
//true 本人 false 他人
export function isMineHomeIndex(id) {
  let localUserId = getUserId()
  if(localUserId == null) {
    return false
  }
  return id === localUserId ? true : false
}

export default { isLogin, getUserName, getUserId, getUserAvatar, isMineHomeIndex }