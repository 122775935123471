import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/avater.png'


const _withScopeId = n => (_pushScopeId("data-v-55f6e658"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatarBox" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: _imports_0,
  alt: "",
  class: "avatar"
}
const _hoisted_4 = { style: {"color":"rgb(157, 157, 245)","margin-left":"6px","font-size":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imgSrc!=null)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imgSrc,
          alt: "",
          class: "avatar"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.imgSrc==null)
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.userName), 1)
  ]))
}