
  import { defineComponent, reactive, ref } from 'vue';
  import { getUserName, isLogin, getUserAvatar } from '../../utils/account.js';
  export default defineComponent({
    name: 'MyAvatar',
    props: {
      imgSrc: null,
      userName: null,
      //0 代表本地用户， 1代表其他用户
      aType: null
    },
    setup(props, ctx) {
      // console.log('props.imgsrc', props.imgSrc)
      const imgSrc = ref(null)
      const userName = ref(null)
      if(props.aType==null||undefined || 0){
        imgSrc.value = getUserAvatar()
        userName.value = getUserName()
      }else {
        imgSrc.value = props.imgSrc
        userName.value = props.userName
      }
      return {
        imgSrc,
        userName,
        isLogin
      }
    }
  })
