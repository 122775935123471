<template>
  <a-layout-content 
  :style="{ backgroundColor: '#F9F6F5 ' , minHeight: '100%', width: '60%', margin: 'auto', padding: '20px 20px', borderRadius: '16px' }"
   >
    <a-empty v-if="!userName"><span slot="description">暂无此用户哦</span></a-empty>
    <div class="header" v-if="userName">
      <div class="avatar">
        <my-avatar v-if="isMine"></my-avatar>
        <my-avatar v-if="!isMine" :imgSrc="avatar" :userName="userName" :aType="1"></my-avatar>
      </div>
      <div class="info">
        <span class="countItem">{{indexName}}文章:{{blogNum}} <a-divider type="vertical" /></span>
        <span class="countItem">{{indexName}}收获点赞:{{likesNum}} <a-divider type="vertical" /></span>
        <span class="countItem">{{indexName}}评论总数:{{cmtNum}}</span>
      </div>
    </div>
    <div class="menuBar" v-if="userName">
      <a-menu v-model="current" mode="horizontal" style="float: left; width: 100%;" v-if="isMine" >
        <a-menu-item key="blogs" @click="goMyBlog">
          <a class="menuTitle" ><ordered-list-outlined />{{indexName}}文章</a>
        </a-menu-item> 
        <a-menu-item key="cmts" @click="goMyCmts">
          <a class="menuTitle" ><message-outlined />{{indexName}}评论</a>
        </a-menu-item>
        <a-menu-item key="stars" @click="goMyStars">
          <a class="menuTitle"><star-outlined />{{indexName}}收藏</a>
        </a-menu-item>
      </a-menu>
      <a-menu v-model="current" mode="horizontal" style="float: left;" v-if="!isMine">
        <a-menu-item key="blogs">
          <a class="menuTitle" @click="goMyBlog"><ordered-list-outlined />{{indexName}}文章</a>
        </a-menu-item> 
      </a-menu>
    </div>
    <div class="mainContent" v-if="userName">
      <router-view></router-view>
    </div>
  </a-layout-content>
</template>

<script>
  import * as ACC_Util from '../../utils/account.js';
  import * as ACCOUNT from '../../api/account.js';
  import { OrderedListOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons-vue';
  import MyAvatar from '@/components/common/MyAvatar'
  export default {
    name: 'PersonSpace',
    components: {
      OrderedListOutlined,
      MessageOutlined,
      StarOutlined,
      MyAvatar
    },
    data() {
      return {
        current: ['blogs'],
        userName: null,
        blogNum: 0,
        likesNum: 0,
        cmtNum: 0,
        avatar: null,
        isMine: false,
        indexName: '我的',
        myMenus:[
          {
            id: '1',
            key: 'blogs',
            path: '',
            icon: 'ordered-list',
            title: '我的文章'
          },
          {
            id: '2',
            key: 'cmts',
            path: 'mycmts',
            icon: 'message',
            title: '我的评论'
          },
          {
            id: '3',
            key: 'msg',
            path: 'mymsgs',
            icon: 'bell',
            title: '我的消息'
          },
          {
            id: '4',
            key: 'stars',
            path: 'stars',
            icon: 'star',
            title: '我的收藏'
          }
        ]
      }
    },
    mounted() {
      // console.log('test', this.$route)
      this.handleCurrent()
      this.loadCountData(this.$route.params.pid)
      this.setIsMe(this.$route.params.pid)
    },
    watch: {
      '$route.params.pid': {
        handler: function(pid) {
          // console.log(id)
          if(pid!==undefined){
            this.loadCountData(pid)
            this.setIsMe(pid)
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      goMyCmts() {
        this.$router.push({
          path: `/space/${this.$route.params.pid}/mycmts`,
        })
      },
      goMyBlog() {
        this.$router.push({
          path: `/space/${this.$route.params.pid}`,
        })
      },
      goMyStars() {
        this.$router.push({
          path: `/space/${this.$route.params.pid}/mystars`,
        })
      },
      handleCurrent() {
        if(this.$route.name == 'MyCmtList') {
          this.current = ['cmts']
        }
      },
      loadCountData(id) {
        let params = new FormData()
        params.append('uid', id)
        ACCOUNT.srhUserCBDataByUId(params).then(res=>{
          // console.log('cb data', res)
          if(res.data.code == 200 && res.data.data) {
            this.userName = res.data.data.userName
            this.blogNum = res.data.data.blogNum
            this.likesNum = res.data.data.likes
            this.cmtNum = res.data.data.cmtNum
            this.avatar = res.data.data.avatar
          }
        })
      },
      setIsMe(id) {
        this.isMine = ACC_Util.isMineHomeIndex(id)
        // console.log('id', id)
        // console.log('isMe', this.isMine)
        if(!this.isMine) {
          this.indexName = '他的'
        }else {
          this.indexName = '我的'
        }
      }
    },
  }
</script>

<style scoped>
  .header {
    width: 640px;
    height: 100px;
    display: flex;
  }
  .avatar {
    float: left;
    width: 140px;
    height: 80px;
    margin-left: -2px;
  }
  .info {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 14px;
    padding: 5px 10px;
  }
  .countItem {
    float: left;
  }
  .menuBar {
    width: 640px;
    height: 70px;
  }
  .menuTitle {
    color: #646464;
    font-size: 15px;
    font-weight: 700;
  }
  .menuTitle:active {
    color: #3f96f3;
    font-size: 15px;
    font-weight: 700;
  }
</style>